// App.js
import React, { useEffect, useState } from "react";
import { getToken, getUserData } from "./services/oauthService";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Link,
} from "react-router-dom";
import axios from "axios";
import Logo from "./assets/logo_escura.png";

import UserPage from "./UserPage";
import Chat from "./Chat";
import NFE from "./NFE";

const App = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const code = query.get("code");
    //const localDevToken = process.env.REACT_APP_LOCAL_DEV_TOKEN || null;
    const localDevToken = process.env.REACT_APP_LOCAL_DEV_TOKEN;
    const token = localDevToken || localStorage.getItem("access_token");

    if (localDevToken) {
      localStorage.setItem("access_token", token);
      // Se o token local de desenvolvimento estiver presente, considera o usuário autenticado
      console.log("Token de desenvolvimento detectado. Usuário autenticado.");
    } else if (code) {
      console.log("Code recebido, autenticando...")
      getToken(code).then((token) => {
        console.log("Obtendo dados do usuário...")
        localStorage.setItem("access_token", token);
        getUserData(token).then((data) => {
          setUserData(data);
        });
      });
    } else {
      console.log("Redirecionando autenticação...")
      // Caso contrário, redireciona para o OAuth
      redirectToOAuth();
    }
  }, []);


  if (!userData) {
    localStorage.removeItem("access_token");
  }
  const redirectToOAuth = () => {
    const authorizeUrl = `${process.env.REACT_APP_OAUTH_AUTHORIZE_URL}?response_type=code&client_id=${process.env.REACT_APP_CLIENT_ID}&redirect_uri=${window.location.origin}&scope=openid`;
    window.location.href = authorizeUrl;
  };

  return (
    <Router>
      <div className="App">
        <nav className="navbar">
          <img src={Logo} alt="Logo" className="logo" />
          <Link to="/chat">Chat</Link>
          <Link to="/user">User</Link>
          <Link to="/nfe">NFE</Link>
        </nav>
        <Routes>
          <Route path="/" element={<Navigate to="/user" />} />
          <Route
            path="/user"
            element={
              <ProtectedRoute>
                <UserPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/chat"
            element={
              <ProtectedRoute>
                <Chat />
              </ProtectedRoute>
            }
          />
          <Route
            path="/nfe"
            element={
              <ProtectedRoute>
                <NFE />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

const ProtectedRoute = ({ children }) => {
  const token =
    process.env.REACT_APP_LOCAL_DEV_TOKEN ||
    localStorage.getItem("access_token");
  return token ? children : <Navigate to="/" />;
};

export default App;
