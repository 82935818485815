import axios from 'axios';

const tokenUrl = process.env.REACT_APP_OAUTH_TOKEN_URL;
const userDataUrl = process.env.REACT_APP_USER_DATA_URL;

export const getToken = async (code) => {
  const params = new URLSearchParams();
  params.append('grant_type', 'authorization_code');
  params.append('client_id', process.env.REACT_APP_CLIENT_ID);
  params.append('client_secret', process.env.REACT_APP_CLIENT_SECRET);
  params.append('code', code);
  params.append('redirect_uri', window.location.origin);

  try {
    const response = await axios.post(tokenUrl, params);
    return response.data.access_token;
  } catch (error) {
    console.error('Error fetching token', error);
    throw error;
  }
};

export const getUserData = async (token) => {
  try {
    const response = await axios.get(userDataUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching user data', error);
    throw error;
  }
};