// UserPage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';

const UserPage = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('access_token');
    if (token) {
      fetchUserData(token);
    }
    else {
      setUserData({ "message": "Access token not set." });
    }
  }, []);

  const fetchUserData = async (token) => {
    try {
      const response = await axios.get(process.env.REACT_APP_USER_DATA_URL, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setUserData(response.data);
    } catch (error) {
      console.error('Erro ao buscar dados do usuário:', error);
    }
  };

  return (
    <div>
      <h1>Dados do Usuário Logado</h1>
      {userData ? (
        <pre>{JSON.stringify(userData, null, 2)}</pre>
      ) : (
        <p>Carregando...</p>
      )}
    </div>
  );
};

export default UserPage;