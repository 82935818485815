// NFE.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./form.css";

const NFE = () => {
  const [formData, setFormData] = useState({});
  const [estados, setEstados] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [regimeTribuario, setRegimeTributario] = useState([]);
  const [selectedregimeTribuario, setSelectedRegimeTributario] = useState("");
  const [regimeTribuarioEspecial, setRegimeTributarioEspecial] = useState([]);
  const [selectedregimeTribuarioEspecial, setSelectedRegimeTributarioEspecial] =
    useState("");
  const [selectedEstado, setSelectedEstado] = useState("");
  const [selectedCidade, setSelectedCidade] = useState("");
  const [certificadoAtivo, setCertificadoAtivo] = useState(false);
  const [formVisible, setFormVisible] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const token = localStorage.getItem("access_token");
  const backend_url = `${process.env.REACT_APP_BACKEND_URL}`;

  const formatCpfCnpj = (value) => {
    value = value.replace(/\D/g, ""); // Remove todos os caracteres não numéricos
    if (value.length <= 11) {
      // Formata como CPF (000.000.000-00)
      return value
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d{1,2})$/, "$1-$2");
    } else {
      // Formata como CNPJ (00.000.000/0000-00)
      return value
        .replace(/(\d{2})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1.$2")
        .replace(/(\d{3})(\d)/, "$1/$2")
        .replace(/(\d{4})(\d{1,2})$/, "$1-$2");
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("access_token");
    const backend_url = `${process.env.REACT_APP_BACKEND_URL}`;

    const fetchArrendatario = async () => {
      try {
        const response = await axios.get(
          `${backend_url}/empresas/arrendatario/`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setFormData(response.data);
        setSelectedRegimeTributario(response.data.regime_tributario || "");
        setSelectedEstado(response.data.uf || "");
        setSelectedCidade(response.data.cidade || "");
      } catch (error) {
        console.error("Erro ao carregar dados do arrendatário:", error);
      }
    };

    // Carregar lista de estados do Brasil usando a API do IBGE
    const fetchRegimeTributario = async () => {
      try {
        const response = await axios.get(`${backend_url}/regime-tributario`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const result = response.data.sort((a, b) =>
          a.nome.localeCompare(b.nome)
        );
        setRegimeTributario(result);
      } catch (error) {
        console.error("Erro ao carregar regime tributario:", error);
      }
    };

    // Carregar lista de estados do Brasil usando a API do IBGE
    const fetchRegimeTributarioEspecial = async () => {
      try {
        const response = await axios.get(
          `${backend_url}/regime-tributario-especial`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const result = response.data.sort((a, b) =>
          a.nome.localeCompare(b.nome)
        );
        setRegimeTributarioEspecial(result);
      } catch (error) {
        console.error("Erro ao carregar regime tributario:", error);
      }
    };

    // Carregar lista de estados do Brasil usando a API do IBGE
    const fetchEstados = async () => {
      try {
        const response = await axios.get(`${backend_url}/estados`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const estadosOrdenados = response.data.sort((a, b) =>
          a.nome.localeCompare(b.nome)
        );
        setEstados(estadosOrdenados);
      } catch (error) {
        console.error("Erro ao carregar estados:", error);
      }
    };

    fetchEstados();
    fetchArrendatario();
    fetchRegimeTributario();
    fetchRegimeTributarioEspecial();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEstadoChange = async (e) => {
    const uf = e.target.value;
    setSelectedEstado(uf);
    setFormData((prevData) => ({ ...prevData, uf }));
    setSelectedCidade("");
    try {
      const response = await axios.get(`${backend_url}/cidades/uf/${uf}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setCidades(response.data);
    } catch (error) {
      console.error("Erro ao carregar cidades:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post(
        `${backend_url}/empresas`,
        {
          formData,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setFormVisible(false);
      alert("Dados enviados com sucesso!");
      setErrorMessage("");
    } catch (error) {
      console.error("Erro ao enviar dados:", error);
      setErrorMessage(
        "Falha ao enviar dados. Verifique os detalhes e tente novamente."
      );
      alert("Falha ao enviar dados.");
    }
  };

  return (
    <div id="form">
      <h2>Cadastro de Empresa</h2>
      {formVisible ? (
        <form className="form-container" onSubmit={handleSubmit}>
          <fieldset>
            <legend>Dados da Empresa</legend>
            <div className="form-group">
              <label className="required">CNPJ:</label>
              <input
                type="text"
                name="cpfcnpj"
                pattern="\d{2}\.\d{3}\.\d{3}/\d{4}-\d{2}"
                placeholder="00.000.000/0000-00"
                value={formData.cpfcnpj || ""}
                onChange={(e) => {
                  const formattedValue = formatCpfCnpj(e.target.value);
                  setFormData((prevData) => ({
                    ...prevData,
                    cpfcnpj: formattedValue,
                  }));
                }}
                required
              />
            </div>
            <div className="form-group">
              <label className="required">Razão Social:</label>
              <input
                type="text"
                name="razao_social"
                value={formData.razao_social || ""}
                onChange={handleInputChange}
                required
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>Dados da Empresa</legend>
            <div className="form-group">
              <label className="required">CEP:</label>
              <input
                type="text"
                name="cep"
                pattern="\d{5}-\d{3}"
                placeholder="00000-000"
                value={formData.cep || ""}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label className="required">Estado:</label>
              <select
                value={selectedEstado}
                name="uf"
                onChange={handleEstadoChange}
              >
                <option value="">Selecione o Estado</option>
                {estados.map((estado) => (
                  <option key={estado.id} value={estado.uf}>
                    {estado.nome}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label className="required">Cidade:</label>
              <select
                name="cidade"
                value={selectedCidade}
                onChange={(e) => {
                  setSelectedCidade(e.target.value);
                  handleInputChange(e);
                }}
                disabled={!selectedEstado}
              >
                <option value="">Selecione a Cidade</option>
                {cidades.map((cidade) => (
                  <option key={cidade.id} value={cidade.nome}>
                    {cidade.nome}
                  </option>
                ))}
              </select>
            </div>

            <div className="form-group">
              <label className="required">Logradouro:</label>
              <input
                type="text"
                name="logradouro"
                value={formData.logradouro || ""}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label className="required">Número:</label>
              <input
                type="text"
                name="numero"
                value={formData.numero || ""}
                onChange={handleInputChange}
                required
              />
            </div>
            <div className="form-group">
              <label>Complemento:</label>
              <input
                type="text"
                name="complemento"
                value={formData.complemento || ""}
                onChange={handleInputChange}
              />
            </div>
          </fieldset>
          <fieldset>
            <legend>Informações fiscais</legend>
            <div className="form-group">
              <label className="required">Inscrição Municipal:</label>
              <input
                type="text"
                name="insc_municipal"
                value={formData.insc_municipal || ""}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label className="required">Regime Fiscal da Sociedade:</label>
              <select
                name="regime_tributario"
                value={selectedregimeTribuario}
                onChange={handleInputChange}
              >
                <option value="">Selecione o regime tributário</option>
                {Array.isArray(regimeTribuario) &&
                  regimeTribuario.map((reg) => (
                    <option key={reg.id} value={reg.id}>
                      {reg.nome}
                    </option>
                  ))}
              </select>
            </div>

            <div className="form-group">
              <label className="required">
                Possui Registro Tributário Especial:
              </label>
              <select
                name="regime_tribuario_especial"
                value={selectedregimeTribuarioEspecial}
                onChange={handleInputChange}
              >
                <option value="">Selecione o regime tributário especial</option>
                {Array.isArray(regimeTribuarioEspecial) &&
                  regimeTribuarioEspecial.map((reg) => (
                    <option key={reg.id} value={reg.id}>
                      {reg.nome}
                    </option>
                  ))}
              </select>
            </div>

            <div className="form-group">
              <label className="required">CNAE Padrão:</label>
              <input
                type="text"
                name="cnae"
                value={formData.cnae || ""}
                onChange={handleInputChange}
                required
              />
            </div>

            <div className="form-group">
              <label className="required">Aliquota ISS:</label>
              <input
                type="number"
                name="aliquota_iss"
                value={formData.aliquota_iss || ""}
                onChange={handleInputChange}
                required
              />
            </div>
          </fieldset>
          <div className="checkbox-group">
            <label>
              <input
                type="checkbox"
                checked={certificadoAtivo}
                onChange={() => setCertificadoAtivo(!certificadoAtivo)}
              />
              Atualizar Certificado Digital
            </label>
          </div>

          {/* Campos Condicionais para Certificado Digital */}
          {certificadoAtivo && (
            <>
              <div className="form-group">
                <label>Certificado Digital:</label>
                <input type="file" />
              </div>

              <div className="form-group">
                <label>Senha Certificado:</label>
                <input type="password" />
              </div>
            </>
          )}

          <button type="submit">Enviar</button>
        </form>
      ) : (
        <p>Formulário enviado com sucesso!</p>
      )}

      {errorMessage && <p className="error-message">{errorMessage}</p>}
    </div>
  );
};

export default NFE;
